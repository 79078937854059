import { members, indonesia_payment } from "../../js/path";
import _ from "lodash";
import axios from "axios";

const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;

export default {
  data() {
    return {
      activeTab: "all",
      pageNo: null,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      currentPage: 1,
      params: "",
      key: 0,
      form: {
        member_id: "",
        transaction_amt: "",
        percentage: "",
        notes: "",
      },
      fields: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "username",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "user_reference_type",
          label: "User Type",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "total_points",
          class: "w-250",
          label: "Total Points",
        },
        {
          key: "balance",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "available_points",
          class: "w-250",
          label: "Available Points",
        },
        {
          key: "redeem_points",
        },
        {
          key: "is_active",
          label: "Status",
        },
      ],
      memberList: [],
    };
  },
  methods: {
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },
    searchFor() {
      if (this.filter.length > 0) {
        this.fetchData();
      } else {
        this.fetchData();
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData();
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async fetchData(txt) {
      this.activeTab = txt;
      this.$store.commit("loader/updateStatus", true);
      let request = `${indonesia_payment?.getWallet}?page=${
        this.currentPage ?? 1
      }&q=${this.filter ?? ""}&filter=${this.activeTab}`;
      axios
        .get(`${apiEndpoint}${request}`)
        .then((response) => {
          const res = response?.data;
          if (res) {
            this.tableData = res?.response;
          }
          this.$store.commit("loader/updateStatus", false);
        })
        .catch((err) => {
          this.$store.commit("toast/updateStatus"),
            {
              status: true,
              icon: "error",
              title: "Something went wrong",
            };
          console.log(err);
          this.$store.commit("loader/updateStatus", false);
        });
    },

    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = `${apiEndpoint}${indonesia_payment?.updateWalletStatus}/${id}`;
        const data = await axios.post(url, {
          status: !this.tableData.data[index].is_active,
        });
        if (data.status) {
          this.tableData.data[index].is_active =
            !this.tableData.data[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Status updated successfully",
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    fetchMemberList: _.debounce(async function (query) {
      this.$store.commit("loader/updateStatus", false);
      this.memberList = [];
      if (query != "" && query != null) {
        this.$store.commit("loader/updateStatus", true);
        const url = members.getMembers;
        const data = await this.getRequest(url + "?search=" + query);
        if (data.status) {
          this.memberList = data.response.data.map((member) => ({
            member_id: member.id,
            fnameAndLname: `${member.fname} ${member.lname} - ${member.mobile_number}`,
          }));
        }
      }
      this.$store.commit("loader/updateStatus", false);
    }, 1000),

    async submitData() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      this.$store.commit("loader/updateStatus", true);
      try {
        let pointsData = {};
        pointsData["user_reference_id"] = this?.form?.member_id?.member_id;
        for (var key in this.form) {
          if (key !== "member_id") {
            pointsData[key] = this.form[key];
          }
        }
        let url = indonesia_payment?.getWallet;
        let data = await axios.post(`${apiEndpoint}${url}`, pointsData);
        console.log(data);
        if (data?.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.data.message,
          });
          this.$router.push("/wallet");
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err?.data ? err?.data?.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.params = `&page=${value}`;
        this.fetchData(this.activeTab);
        this.pageNo = this.currentPage;
      },
    },
  },
  created() {
    this.fetchData();
  },
};
